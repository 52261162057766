import { useAppSelector, useAppDispatch } from "store/hooks";
import { updateNotes, Measure } from "store/beat/slice";
import Playhead from "screens/Main/Playhead";
import AnimatedList from "components/AnimatedList";
import { range } from "lodash";
import {
  INSTRUMENTS,
  INSTRUMENT_KEYS,
  INSTRUMENT_TO_COLOR_MAP,
  INSTRUMENT_TO_OP_COLOR_MAP,
  MAX_ROW_MEASURES_16,
  MAX_ROW_MEASURES_8,
  MIN_MEASURE_WIDTH,
  SUBDIVISION_WIDTH_DIFF,
} from "utils/constants";

import StaffSvg from "./Staff/StaffSvg";
import StaffSvg16 from "./Staff/StaffSvg16";
import Sticking from "./Sticking";
import Grid from "./Grid";
import Counting from "./Counting";

interface Props {
  availableSheetWidth: number;
  isActive: boolean;
  measure: Measure;
  measureIndex: number;
  readOnly: boolean;
}

interface InstrumentBackgroundStyle {
  background: string;
}

const instrumentsLength = INSTRUMENTS.length + 1;

const NoteGrid = ({
  availableSheetWidth, isActive, measure, measureIndex, readOnly,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    countingIn,
    playing,
  } = useAppSelector((store) => store.drumroom);

  const {
    activeMeasureIndex,
    color,
    counting,
    grid,
    notes,
    sticking,
    subdivision,
  } = useAppSelector((store) => store.beat.present);

  const hidden = measure.isolate;
  const gridDivision = subdivision ? 16 : 8;
  const opacity = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  const gridOpacity: InstrumentBackgroundStyle[][] = Array.from({ length: instrumentsLength }, () => []);

  const handleUpdateNotes = (inst: number, index: number, type?: string, add?: boolean) => {
    dispatch(updateNotes({
      activeNote: {
        inst,
        index,
        type,
      },
      add,
      activeMeasureIndex,
    }));
  };

  const maxMeasuresForGridAlwaysOn = subdivision ? MAX_ROW_MEASURES_16 : MAX_ROW_MEASURES_8;
  const isGridOpen = grid && (
    !((notes.length > maxMeasuresForGridAlwaysOn) && !isActive)
    && !((notes.length > maxMeasuresForGridAlwaysOn) && playing)
  );

  if (!(hidden.every((e) => e === "") && !countingIn)) {
    for (let i = 0; i < opacity.length; i += (16 / gridDivision)) {
      if (hidden[i] && !countingIn) {
        opacity[i] = 1;

        INSTRUMENT_KEYS.forEach((key, index) => {
          if (measure[key][i]) {
            if (color) {
              gridOpacity[index][i] = { background: INSTRUMENT_TO_COLOR_MAP[key] };
            } else {
              gridOpacity[index][i] = { background: "rgba(0, 0, 0, 1)" };
            }
          }
        });
      } else {
        opacity[i] = 0.1;

        INSTRUMENT_KEYS.forEach((key, index) => {
          if (measure[key][i]) {
            if (color) {
              gridOpacity[index][i] = { background: INSTRUMENT_TO_OP_COLOR_MAP[key] };
            } else {
              gridOpacity[index][i] = { background: "rgba(0, 0, 0, 0.1)" };
            }
          }
        });
      }
    }
  }

  let beat = [];
  const beatIsEmpty = [];
  const showSticking = [];
  for (let i = 0; i < 16; i += 1) {
    beat = INSTRUMENT_KEYS.map((key) => measure[key][i]);
    showSticking[i] = beat.filter((inst) => inst !== "").length >= 1;
    beatIsEmpty[i] = beat.every((e) => e === "");
  }

  // It only works when first row has same number of measures as second row. That covers most cases though
  const measureWidth = MIN_MEASURE_WIDTH + (subdivision ? SUBDIVISION_WIDTH_DIFF : 0);
  const measuresInRow = Math.min(
    Math.floor(availableSheetWidth / measureWidth),
    subdivision ? MAX_ROW_MEASURES_16 : MAX_ROW_MEASURES_8,
  );
  const firstIndexInRow = activeMeasureIndex - (activeMeasureIndex % measuresInRow);
  const lastIndexInRow = firstIndexInRow + measuresInRow;
  const validRowIndices = range(firstIndexInRow, lastIndexInRow);
  const isSameRowAsActiveMeasure = validRowIndices.includes(measureIndex);

  const listItems = [
    {
      component: (
        <Counting
          beatIsEmpty={beatIsEmpty}
          division={subdivision ? 16 : 8}
          isFirst={measureIndex === 0}
        />
      ),
      hidden: false,
      key: "1",
      open: counting,
    },
    {
      component: (
        <Sticking
          isFirst={measureIndex === 0}
          measure={measure}
          readOnly={readOnly}
          showSticking={showSticking}
          subdivision={subdivision}
          updateNotes={handleUpdateNotes}
        />
      ),
      hidden: false,
      key: "2",
      open: sticking,
    },
    {
      component: (
        <Grid
          activeMeasureIndex={activeMeasureIndex}
          color={color}
          gridOpacity={gridOpacity.reverse()}
          isActive={measureIndex === activeMeasureIndex}
          isFirst={measureIndex === 0}
          measure={measure}
          measureChangeAnimationApplies={notes.length > (subdivision ? MAX_ROW_MEASURES_16 : MAX_ROW_MEASURES_8)}
          readOnly={readOnly}
          subdivision={subdivision}
        />
      ),
      hidden: grid && isSameRowAsActiveMeasure && !playing,
      key: `3-${subdivision ? 16 : 8}`, // re-render items when switching subdivision in case of maxHeight change
      open: isGridOpen,
    },
  ];

  return (
    <>
      {isActive && <Playhead />}

      {subdivision ? (
        <StaffSvg16
          colored={color}
          measure={measure}
          showClefs={measureIndex === 0}
        />
      ) : (
        <StaffSvg
          colored={color}
          measure={measure}
          showClefs={measureIndex === 0}
        />
      )}

      <AnimatedList
        items={listItems}
        subdivision={subdivision}
      />
    </>
  );
};

export default NoteGrid;

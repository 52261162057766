export type AllNotes = Instruments | "sticking" | "isolate";
export type Instruments = "crash" | "hiHat" | "hiTom" | "midTom" | "snare" | "loTom" | "bass" | "hatFoot";
export type Toms = "hiTom" | "midTom" | "loTom"

export interface ObjectKeys {
  [key: string]: AllNotes;
}

interface NotesIndexToKey extends ObjectKeys {
  readonly 0: "crash";
  readonly 1: "hiHat";
  readonly 2: "hiTom";
  readonly 3: "midTom";
  readonly 4: "snare";
  readonly 5: "loTom";
  readonly 6: "bass";
  readonly 7: "hatFoot";
  readonly 8: "isolate";
  readonly 9: "sticking";
}

const NOTES_INDEX_TO_KEY: NotesIndexToKey = {
  0: "crash",
  1: "hiHat",
  2: "hiTom",
  3: "midTom",
  4: "snare",
  5: "loTom",
  6: "bass",
  7: "hatFoot",
  8: "isolate",
  9: "sticking",
};

export default NOTES_INDEX_TO_KEY;

/* eslint-disable max-len */

import { BaseProps } from "./baseProps";

const Crash = ({
  colored, index, onClick, opacity,
}: BaseProps) => (
  <path
    d="m61.91146,42.075c0.12,-0.03 0.24,-0.03 0.36,0.03c0.03,0.03 0.93,0.72 1.95,1.56l1.86,1.5l1.86,-1.5c1.02,-0.84 1.92,-1.53 1.95,-1.56c0.21,-0.12 0.33,-0.09 0.75,0.24c0.3,0.27 0.36,0.36 0.36,0.54c0,0.03 -0.03,0.12 -0.06,0.18c-0.03,0.06 -0.9,0.75 -1.89,1.56l-1.8,1.47c0,0.03 0.81,0.69 1.8,1.5c0.99,0.81 1.86,1.5 1.89,1.56c0.03,0.06 0.06,0.15 0.06,0.18c0,0.18 -0.06,0.27 -0.36,0.54c-0.42,0.33 -0.54,0.36 -0.75,0.24c-0.03,-0.03 -0.93,-0.72 -1.95,-1.56l-1.86,-1.5l-1.86,1.5c-1.02,0.84 -1.92,1.53 -1.95,1.56c-0.21,0.12 -0.33,0.09 -0.75,-0.24c-0.3,-0.27 -0.36,-0.36 -0.36,-0.54c0,-0.03 0.03,-0.12 0.06,-0.18c0.03,-0.06 0.9,-0.75 1.89,-1.56l1.8,-1.47c0,-0.03 -0.81,-0.69 -1.8,-1.5c-0.99,-0.81 -1.86,-1.5 -1.89,-1.56c-0.06,-0.12 -0.09,-0.21 -0.03,-0.36c0.03,-0.09 0.57,-0.57 0.72,-0.63zm9.063,-23.075l0,26.35l-0.6,0l0,-22.35l0.6,0zm-9,3 M58,45.7 L74,45.7 L74,46.3 L58,46.3 Z"
    fill={colored ? "rgb(52, 188, 188)" : "#00000"}
    fillOpacity={opacity}
    onClick={onClick}
    transform={`translate(${30 * index}, -0.5)`}
  />
);

export default Crash;

import { useEffect, useRef } from "react";
import {
  ToneAudioBuffer,
  Transport,
  Sampler,
} from "tone";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { getLoop } from "store/drumroom/selectors";
import { setCountingIn, setPlaying, setStep } from "store/drumroom/slice";
import { setActiveMeasure, advanceActiveMeasure, Measure } from "store/beat/slice";
import NOTES_INDEX_TO_KEY from "utils/notesIndexToKey";
import removeOddNotesIfApplies from "utils/removeOddNotesIfApplies";
import { INSTRUMENTS } from "utils/constants";

// automatically knows to look in /public, no matter the context

const HAT_FOOT = new ToneAudioBuffer("/hat-foot.mp3");
const CRASH = new ToneAudioBuffer("/crash.mp3");
const HAT = new ToneAudioBuffer("/hat.mp3");
const SNARE = new ToneAudioBuffer("/snare.mp3");
const BASS = new ToneAudioBuffer("/bass.mp3");
const METRONOME_HI = new ToneAudioBuffer("/metronomehi.mp3");
const METRONOME_LO = new ToneAudioBuffer("/metronomelo.mp3");
const HAT_OPEN = new ToneAudioBuffer("/open.mp3");
const SNARE_RIM = new ToneAudioBuffer("/rim.mp3");
const HI_TOM = new ToneAudioBuffer("/hi-tom.mp3");
const MID_TOM = new ToneAudioBuffer("/mid-tom.mp3");
const LO_TOM = new ToneAudioBuffer("/lo-tom.mp3");

const INSTRUMENT_TO_SAMPLE_MAP = {
  Crash: "A5",
  "Hi-Hat": "G5",
  "Hi-Tom": "C4",
  "Mid-Tom": "D4",
  Snare: "E4",
  "Lo-Tom": "F4",
  Bass: "C3",
  "Hi-Hat Pedal": "B3",
} as const;

const keys = new Sampler({
  A5: CRASH,
  G5: HAT,
  C3: BASS,
  B3: HAT_FOOT,
  D3: METRONOME_HI,
  E3: METRONOME_LO,
  A4: HAT_OPEN,
  B4: SNARE_RIM,
  C4: HI_TOM,
  D4: MID_TOM,
  E4: SNARE,
  F4: LO_TOM,
}, {
  release: "4n",
}).toDestination();

const usePlayback = (measure: Measure) => {
  const dispatch = useAppDispatch();
  const {
    beat: {
      present: {
        bpm,
        subdivision,
        swing,
        activeMeasureIndex,
        notes,
        metronome,
      },
    },
    drumroom: {
      alternate,
      countIn,
      countingIn,
      playing,
    },
  } = useAppSelector((store) => store);

  const loop = useAppSelector(getLoop);
  const beatLibraryOpen = useAppSelector((store) => store.drumroom.beatLibraryOpen);

  const playbackRef = useRef({
    countIn,
    bpm,
    metronome,
    countingIn,
    alternate,
    measure: removeOddNotesIfApplies(measure, subdivision),
    swing,
    subdivision,
    loop,
    activeMeasureIndex,
    notes,
    division: subdivision ? 16 : 8,
  });

  playbackRef.current = {
    countIn,
    bpm,
    metronome,
    countingIn,
    alternate,
    measure: removeOddNotesIfApplies(measure, subdivision),
    swing,
    subdivision,
    loop,
    activeMeasureIndex,
    notes,
    division: subdivision ? 16 : 8,
  };

  const maxDelay = subdivision ? 150 : 300;

  const stop = () => {
    Transport.stop();
    Transport.position = 0;
    Transport.cancel();

    if (playing) dispatch(setPlaying(false));
    dispatch(setStep(1));
  };

  const play = () => {
    let index = 0;
    let isOn = !countIn;
    const metronomeInput: boolean[] = [];
    for (let i = 0; i < playbackRef.current.division; i += 1) {
      metronomeInput[i] = !(i % (playbackRef.current.division / 4));
    }
    Transport.scheduleRepeat((time: number) => {
      /* eslint-disable no-shadow */
      const {
        countIn, bpm, metronome, countingIn, alternate, swing, division, loop, notes,
        activeMeasureIndex,
      } = playbackRef.current;
      /* eslint-enable no-shadow */

      if (keys.loaded) {
        const step = index % division;
        if (step === 0 && index !== 0 && !countingIn) {
          isOn = !isOn;
          if (activeMeasureIndex + 1 >= notes.length) {
            dispatch(setActiveMeasure(0));
            dispatch(setStep(1));
            if (!loop) {
              stop();
              return;
            }
          } else {
            dispatch(advanceActiveMeasure());
            dispatch(setStep(1));
          }
        } else {
          setTimeout(
            () => dispatch(setStep(step + 1)),
            (step % 2 ? ((maxDelay * 60) / bpm) * swing : 0),
          );
        }

        // this needs to run AFTER the above block. if not, measure wouldn't be updated for the current repeat.
        /* eslint-disable no-shadow */
        const { measure } = playbackRef.current;

        let playNotes = measure.isolate.every((e) => e === "") && (alternate ? isOn : true);

        if (countingIn) {
          playNotes = false;
        }

        if (metronomeInput[step] && metronome) {
          keys.triggerAttack("E3", time);
        }

        if (countIn && index >= division) {
          // if it makes it in here, countIn is over. index goes from 0-props.division.
          if (countingIn) {
            playNotes = measure.isolate.every((e) => e === "");
            isOn = !isOn;
            dispatch(setCountingIn(false));
          }
          if (alternate) {
            // if nothing is isolated, or a beat is isolated, play that beat
            if ((measure.isolate[step] === "true") && isOn) {
              playNotes = true;
            }
          } else if (measure.isolate[step] === "true") {
            playNotes = true;
          }
        } else if (!countIn) {
          if (alternate) {
            // if nothing is isolated, or a beat is isolated, play that beat
            if ((measure.isolate[step] === "true") && isOn) {
              playNotes = true;
            }
          } else if (measure.isolate[step] === "true") {
            playNotes = true;
          }
        }

        if (playNotes) {
          // we should parallelize into separate threads somehow so they are all triggered at the same moment
          for (let i = 0; i < INSTRUMENTS.length; i += 1) {
            const note = measure[NOTES_INDEX_TO_KEY[i]];

            const input = !!note[step];

            if (input === true) {
              // @ts-ignore
              const ghost = note[step].includes("g");
              // @ts-ignore
              const accent = note[step].includes("a");
              // @ts-ignore
              const open = note[step].includes("o");
              // @ts-ignore
              const rim = note[step].includes("r");

              let velocity = 1;

              if (accent) {
                velocity = 1.75;
              } else if (ghost) {
                velocity = 0.4;
              }
              if (ghost || accent) {
                keys.triggerAttack(INSTRUMENT_TO_SAMPLE_MAP[INSTRUMENTS[i]], time, velocity);
              } else if (open) {
                keys.triggerAttack("A4", time);
              } else if (rim) {
                keys.triggerAttack("B4", time);
              } else {
                keys.triggerAttack(INSTRUMENT_TO_SAMPLE_MAP[INSTRUMENTS[i]], time);
              }
            }
          }
        }

        index += 1;
      }
    }, `${playbackRef.current.division}n`); // this can't be updated without restarting the scheduleRepeat.

    Transport.start();
  };

  const handleUpdatePlaying = () => {
    if (!playing) {
      stop();
    } else {
      play();
    }
  };

  useEffect(() => () => stop(), []);
  useEffect(() => {
    handleUpdatePlaying();
  }, [playing]);

  if (beatLibraryOpen && playing) stop();

  Transport.bpm.value = bpm;
  Transport.swing = swing;
  Transport.swingSubdivision = `${subdivision ? 16 : 8}n`;
};

export default usePlayback;
